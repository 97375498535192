import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import { graphql } from 'gatsby';
import App from '../components/App';
import SEO from '../components/SEO';
import Container from '../components/Container';
import RichText from '../components/RichText';
import HeroBlog from '../components/HeroBlog';
import Sidebar, { SidebarContainer } from '../components/Sidebar';
import AuthorWidget from '../components/AuthorWidget';
import theme from '../theme';
import ShareWidget from '../components/ShareWidget';
import Grid from '../components/Grid';
import CardResource from '../components/CardResource';
import CallToActionSlim from '../components/CallToActionSlim';

const BlogDetail = ({ data, location }) => {
  const { href } = location;
  const {
    contentfulBlog,
    contentfulCallToAction,
    previous,
    next,
  } = data;

  const {
    author,
    body,
    image,
    categories,
  } = contentfulBlog;

  useEffect(() => {
    const post = document.getElementById('body');
    const links = post.querySelectorAll('a');
    links.forEach(el => {
      el.setAttribute('target', '_blank');
    });
  }, []);

  return (
    <App>
      <SEO {...contentfulBlog} />
      <HeroBlog
        {...contentfulBlog}
        subtitle={categories
          && categories
            .map(prop => prop.title)
            .join(', ')
        }
      />
      <Container style={{ width: 1350 }}>
        {image && (
          <Image
            {...image}
            style={{
              borderRadius: theme.borderRadius,
              height: 500,
            }}
          />
        )}

        <SidebarContainer>
          <section style={{ flex: 1 }}>
            <div id="body">
              <RichText body={body} />
            </div>
            <CallToActionSlim {...contentfulCallToAction} />
            <Grid>
              <CardResource
                {...previous}
                url={`/blog/${previous.slug}`}
                topic="Previous"
                half
              />
              <CardResource
                {...next}
                url={`/blog/${next.slug}`}
                topic="Next"
                half
              />
            </Grid>
          </section>
          <Sidebar>
            {author && <AuthorWidget {...author} />}
            <ShareWidget
              url={href}
              title={contentfulBlog.title}
              summary={contentfulBlog.description}
            />
          </Sidebar>
        </SidebarContainer>
      </Container>
    </App>
  );
};

BlogDetail.propTypes = {
  data: PropTypes.shape({
    contentfulBlog: PropTypes.object,
  }).isRequired,
};

export default BlogDetail;

export const query = graphql`

    fragment blogSnippet on ContentfulBlog {
        title 
        slug
        author {
            name
            thumbnail {
                alt: title 
                fluid(maxWidth: 1450) {
                    ...GatsbyContentfulFluid
                }
            }
            bio {
                childContentfulRichText {
                    html
                }
            }
        }
    }

    query getBlogById(
        $contentful_id: String, 
        $prev: String, 
        $next: String
        ){
        contentfulBlog(contentful_id: { eq: $contentful_id } ){
            ...blogSnippet
            date (formatString: "DD MMMM, YYYY")
            categories {
                title
            }
            body {
                childContentfulRichText {
                    html
                }
            }
            image: photo {
                alt: title 
                fluid(maxWidth: 1450) {
                    ...GatsbyContentfulFluid
                }
            }
            
        }
        previous: contentfulBlog(contentful_id: { eq: $prev } ){
            ...blogSnippet
        }
        next: contentfulBlog(contentful_id: { eq: $next } ){
            ...blogSnippet
        }
        contentfulCallToAction(contentful_id: { eq: "6HwnBH5whfGY01J3bfnPE0" }){
            title 
            body {
                childContentfulRichText {
                    html
                }
            }
            buttonLabel 
            buttonDestination
        }
    }
`;
