import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ButtonLink, { ButtonExternalLink } from './Button';
import Grid from './Grid';
import theme from '../theme';

const CallToActionWrapper = styled.aside`
  background-color: ${theme.tertiary};
  border-radius: ${theme.borderRadius};
  color: ${theme.contrast};
  margin: 5vh auto 1rem;
  padding: 4vh 4vw;
  position: relative;
  width: calc(100% - 1rem);

  @media (max-width: 768px){
    padding: 4vh 10vw;
  }
`;

const CallToActionBody = styled.div`
  width: 70%;
`;

const CallToActionHeading = styled.h3`
  color: inherit;
  font-size: calc(1.224em + .5vw);
  margin: 1rem 0;
`;

export default function CallToAction({ title, buttonLabel, buttonDestination }) {
  return (
    <CallToActionWrapper>
      <Grid align="center" justify="space-between">
        <CallToActionBody>
          <CallToActionHeading>
            {title}
          </CallToActionHeading>
        </CallToActionBody>
        {(buttonDestination.includes('www')
          || buttonDestination.includes('http'))
          ? (
            <ButtonExternalLink href={buttonDestination} fill>
              {buttonLabel}
            </ButtonExternalLink>
          )
          : (
            <ButtonLink to={buttonDestination} fill>
              {buttonLabel}
            </ButtonLink>
          )}
      </Grid>
    </CallToActionWrapper>
  );
}

CallToAction.propTypes = {
  title: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonDestination: PropTypes.string.isRequired,
};
