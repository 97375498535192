import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';
import {
  TiSocialFacebook,
  TiSocialTwitter,
  TiSocialLinkedin,
  TiMail,
} from 'react-icons/ti';
import { Label } from './AuthorWidget';
import theme from '../theme';

const ShareWidgetWrapper = styled.div`
    margin-top: 2rem;
    text-align: center;
`;

const ShareButton = styled.div`
    background-color: ${theme.primary};   
    cursor: pointer;
    border-radius: 50%;
    height: 3rem;
    display: inline-block;
    line-height: 3.65;
    margin: .25rem;
    transition: background-color 500ms;
    width: 3rem;

    svg {
        fill: ${theme.contrast};
        height: 1.5rem;
        transition: fill 250ms;
        width: 1.5rem;
    } 

    &:hover,
    &:focus {
        background-color: ${theme.secondary}; 
    }
`;

const ShareWidget = ({ url }) => (
  <ShareWidgetWrapper>
    <Label>Share This</Label>
    <ShareButton>
      <FacebookShareButton url={url}>
        <TiSocialFacebook />
      </FacebookShareButton>
    </ShareButton>
    <ShareButton>
      <LinkedinShareButton url={url}>
        <TiSocialLinkedin />
      </LinkedinShareButton>
    </ShareButton>
    <ShareButton>
      <TwitterShareButton url={url}>
        <TiSocialTwitter />
      </TwitterShareButton>
    </ShareButton>
    <ShareButton>
      <EmailShareButton url={url}>
        <TiMail />
      </EmailShareButton>
    </ShareButton>
  </ShareWidgetWrapper>
);

export default ShareWidget;
