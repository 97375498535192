import styled from 'styled-components';

export const SidebarContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    margin-top: 5vh;

    @media (max-width: 768px){
        flex-direction: row;
    }
`;

export default styled.aside`
    padding-right: 3vw;
    padding-top: 1rem;
    min-width: 325px;
    width: 20vw;

    @media (max-width: 768px){
        margin-top: 1rem;
        padding-right: 0;
        width: 100%;
    }
`;
