import React from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import {
  HeroWrapper,
  HeroContent,
  HeroTitle,
  HeroSubTitle,
} from './HeroInterior';
import { withColourContext } from './NavBar';
import {
  AuthorImageBio,
  AuthorImage,
  Label,
} from './AuthorWidget';
import globalProps from '../props';
import Container from './Container';

function HeroBlog({
  title,
  author,
  date,
  subtitle,
  children,
  setTransparency,
  transparency,
}) {
  if (transparency) setTransparency(false);
  return (
    <HeroWrapper>
      <Container large>
        <HeroContent>
          <Label>{subtitle}</Label>
          <HeroTitle>{title}</HeroTitle>
          {author && (
            <HeroSubTitle style={{ marginTop: '2vh' }}>
              <AuthorImageBio style={{ justifyContent: 'center' }}>
                {author.thumbnail && (
                  <AuthorImage>
                    <Image {...author.thumbnail} />
                  </AuthorImage>
                )}
                <figcaption>
                  {author && author.name}
                  {date && ` | ${date}`}
                </figcaption>
              </AuthorImageBio>
            </HeroSubTitle>
          )}
          {children}
        </HeroContent>
      </Container>
    </HeroWrapper>
  );
};

HeroBlog.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  author: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  date: PropTypes.string.isRequired,
  children: globalProps.children.isRequired,
};

export default withColourContext(HeroBlog);
