import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Image from 'gatsby-image';
import { navigate, Link } from 'gatsby';
import { AuthorImageBio, AuthorImage, Label } from './AuthorWidget';
import globalProps from '../props';
import theme from '../theme';

export const CardWrapper = styled.article`
  height: auto;
  padding: .5vw;
  width: ${({ half, full, small }) => {
    if (full) return '100%';
    if (half) return '50%';
    if (small) return '25%';
    return '33%';
  }};

  @media (max-width: 768px){
    padding: 1vw;
    width: 50%;
  }

  @media (max-width: 567px){
    padding: 1vw;
    width: 100%;
  }
`;

export const CardLinkWrapper = css`
  background: ${theme.contrast};
  border: 3px solid ${theme.muted};
  box-sizing: border-box;
  border-radius: ${theme.borderRadius};
  display: block;
  height: 100%;
  padding: 2vw;
  text-decoration: none;
  transition-duration: 250ms;
  transition-property:
    background,
    border-color,
    box-shadow;
    
  @media (max-width: 768px){
    padding: 4vw;
  }

  ${props => props.withMedia && css`
    align-items: center;
    border-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: flex;
    height: 200px;
  `}
`;

const CardBody = styled(Link)`
  ${CardLinkWrapper}
  &:hover {
    background: ${theme.muted};
    box-shadow: 3px 5px 19px  ${theme.darkMuted};
  }
`;

const CardBodyHref = styled.a.attrs({ target: '_blank' })`
  ${CardLinkWrapper}
  &:hover {
      background: ${theme.muted};
      box-shadow: 3px 5px 19px  ${theme.darkMuted};
    }
`;

const CardHeading = styled.h3`
    color: ${theme.dark};
    font-family: inherit;
    font-size: calc(1.266em + .25vw);
    margin-top: 1rem;

    @media (max-width: 768px){
        font-size: calc(1.133em + .5vw);
    }
`;

const CardMedia = styled.div`
    border-top-left-radius: ${theme.borderRadius};
    border-top-right-radius: ${theme.borderRadius};
    cursor: pointer;
    overflow: hidden;
    transition: filter 250ms;

    &:hover {
      filter: brightness(110%);
    }
`;

const CardResource = ({
  title,
  image,
  logo,
  url,
  link,
  file,
  author,
  type,
  topic,
  internal,
  ...rest
}) => {
  const cbLink = () => navigate(url);
  const LinkWrap = (link || file) ? CardBodyHref : CardBody;

  let labelText = type || topic || internal.type;
  if (labelText === 'ContentfulCaseStudy') {
    labelText = 'Case Study';
  } else if (labelText === 'ContentfulWebinar') {
    labelText = 'Webinar';
  } else if (labelText === 'ContentfulBlog') {
    labelText = null;
  }

  return (
    <CardWrapper {...rest}>
      {image && (
        <CardMedia onClick={cbLink}>
          <Image
            {...image}
            style={{ height: 250 }}
          />
        </CardMedia>
      )}
      <LinkWrap
        to={url}
        withMedia={image}
        href={file ? file.file.url : link}
      >
        <div>
          {labelText && (
            <Label>
              {labelText}
            </Label>
          )}
          <CardHeading>
            {title}
          </CardHeading>
          { /** blog cards */
            author && (
              <AuthorImageBio>
                <AuthorImage>
                  {author.thumbnail && (
                    <Image {...author.thumbnail} />
                  )}
                </AuthorImage>
                <figcaption>
                  {`By ${author.name}`}
                </figcaption>
              </AuthorImageBio>
            )
          }
        </div>
      </LinkWrap>
    </CardWrapper>
  );
};

CardResource.propTypes = {
  title: PropTypes.string.isRequired,
  image: globalProps.image.isRequired,
  logo: globalProps.image.isRequired,
  type: globalProps.image.isRequired,
  internal: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  topic: globalProps.image.isRequired,
  link: PropTypes.string.isRequired,
  author: PropTypes.shape({
    name: PropTypes.string,
    thumbnail: globalProps.image,
  }).isRequired,
  url: PropTypes.string,
  description: PropTypes.shape({
    description: PropTypes.string,
  }).isRequired,
  file: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

CardResource.defaultProps = {
  url: '/',
};

export default CardResource;
